<template>
  <div class="query_wrapper container">
    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共为您找到
          <span class="highlight">{{ total }}</span>
          家符合的企业
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="name">
            <span class="link" @click="toDetail(item.cid)">
              {{ item.cname | noVoid }}
            </span>

            <span v-if="item.ctype" class="tag">{{ item.ctype }}</span>
          </div>

          <div class="desc">
            <div class="desc_item">
              注册资金：
              {{ item.cregCost | wanCost }}
            </div>

            <div class="desc_item">
              业绩数量：
              {{ item.cresultCount || 0 }}
            </div>

            <div class="desc_item">
              注册地区：
              {{ item.clocation | noVoid }}
            </div>
          </div>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="prev, pager, next, jumper"
        :total="paginationTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 组合查询-列表页 */
import { combinedQueryList } from "@/api/combinedQuery";

export default {
  data() {
    return {
      loading: false,
      total: 0,
      paginationTotal: 0,
      form: { pageNum: 1, pageSize: 20 },
      list: [],
    };
  },

  methods: {
    /* 获取数据 */
    // 获取列表数据
    getData() {
      this.loading = true;

      combinedQueryList(this.form).then((res) => {
        const { code, data, msg } = res;
        if (code !== 0) return this.$message.error(msg);
        this.list = data.records;
        this.total = data.total;
        this.paginationTotal = data.total > 2000 ? 2000 : data.total;
        this.loading = false;
      });
    },

    /* 操作 */
    // 跳转到详情页
    toDetail(id) {
      this.$router.push({
        name: "companyDetails",
        params: { name: "资质等级" },
        query: { id },
      });
    },
    // 分页大小改变时触发
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时触发
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      const params = this.$store.state.combinedQueryParams;
      console.log("params>>>", params);

      this.form = { ...this.form, ...params };
      this.getData();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  padding: 20px;
  background-color: #fff;

  .list {
    min-height: calc(100vh - 340px);
    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px solid #efefef;
      cursor: pointer;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 20px;

        .tag {
          font-size: 14px;
          padding: 2px 8px 4px;
          border-radius: 4px;
          line-height: 22px;
          color: #1d89e3;
          background-color: #d7edfb;
        }
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          margin-right: 80px;
          flex: 1;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }

  /deep/.el-empty {
    padding: 140px 0px;
  }

  /deep/.el-pagination {
    text-align: right;
    padding: 20px 30px;
  }

  .total {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
